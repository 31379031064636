import React, { useEffect, useState } from "react"; 
import { element, isEmpty, isNotEmpty } from "../../../Utils/helper";
import ScratchCard from "simple-scratch-card";
import { CgSpinner } from "react-icons/cg";
import PrimaryButton from "../../Common/Button/PrimaryButton";
import { getImageBase64 } from "../../../../backend/templates";
import { useSelector } from "react-redux";
import { CampaignEvents } from "../../../../backend/campaignEvents";
import { GetDEviceInfo } from "../../../../backend/GetDeviceInfo";

const SpinWheelThankyou = ( data ) => { 
    let {result,DIVID,Settings, setformWheel, formWheelObj,wheelFormEvent=null  }= data
    const {campaignID,siteId,renderType,primary_Site_id} = useSelector((state) => ({campaignID:state.campaign_id,siteId:state.site_id,renderType:state.renderType,primary_Site_id:state.primary_Site_id}))

    
    const [isCopied,setCopyStatus] = useState(false)   
    const [isLoaded, setLoaded]=useState(false)
    const [base64, setBase64]=useState("")
    const [cnt, steCnt]=useState(0) 
    const [scratchSize, setScratchSize]=useState([250,250])
    const [resultLoaded,setResultLoaded] = useState(true) 



    useEffect(()=>{ if(resultLoaded){ submitForm(result?.resultText); setResultLoaded(false) } },[resultLoaded,result])

    
    async function submitForm(coupon){
      if(renderType==="liveRender"){
        try{ 
          let newFormObj=formWheelObj
          newFormObj["Win Coupon"]=coupon          
            const CampaignEvent = new CampaignEvents({campaignId:campaignID,site_id:siteId, primary_Site_id:primary_Site_id})        
            const DEviceData = await GetDEviceInfo()
          CampaignEvent.subcripe(newFormObj,encodeURIComponent(wheelFormEvent),DEviceData )
                 
        }catch(e){
            console.log(e)     
        }} else{
            console.log("In Preview")
            
        }
        setformWheel({})
    }

    let couponBg=(result?.couponBgType==="image")? "url("+result?.couponBgImage+") 0% 0% / 100% 100% no-repeat" : result?.couponBgColor || "#f5f5dc";
 
    useEffect(()=>{
        setCopyStatus(false)
        setLoaded(false)
        setBase64("")
        steCnt(0)
        setScratchSize([250,250])
        loadBase64()
    },[])


    useEffect(()=>{

        function updateSize(){
        let tempW=element("scratchParent"+DIVID).clientWidth
        let tempH=element("scratchParent"+DIVID).clientHeight
        setScratchSize([tempW,tempH])
        }

        window.addEventListener('resize', updateSize);
        updateSize();
  
            return () => { window.removeEventListener('resize', updateSize);  }
        }, []);


    async function loadBase64(){
        if(result?.couponStauts){
        let base64Temp= await getImageBase64(result?.couponScratchImage || "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/scratchCard.png",600,600)
        setBase64(base64Temp)
        //   steCnt(o => o+1);
        } 
        setLoaded(true)

    }


    const copy = () => { 
        navigator.clipboard.writeText(result?.resultText); 
        setCopyStatus(true)
        setTimeout(()=>{ setCopyStatus(false) },2100)
    }

return ( 
<div className="w-full h-full flex justify-center absolute top-0 left-0 items-center"  style={{background:`rgb(162 156 156 / 34%)`}} >
<div className="w-[90%] h-[90%] max-w-[510px] min-w-[270px] max-h-[300px] min-h-[190px] bg-white  rounded-lg shadow-lg flex items-center justify-center overflow-auto divScroll" id={"scratchParent"+DIVID}>


      {/* COUPON OR NORMAL RESULT */}
      { result?.couponStauts ?
          <>
            { isNotEmpty(result?.couponScratchImage)   &&
            <div className="SpinScratchCardStyle" style={{ touchAction:"none",	}} >        
                  
                      <div  id={"scratchCardStyle"+DIVID} className=" flex h-full w-full"  key={cnt}>
                      
                      {isNotEmpty(base64) && isLoaded ? 
                      <ScratchCard key={cnt+scratchSize[0]+scratchSize[1]}  cover={base64}  percent={45} width={scratchSize[0]} height={scratchSize[1]}  onComplete={(e) => { }}> 
                        <div className="scratchCardInner flex flex-col justify-center overflow-auto items-center "  style={{background:couponBg}} >
                          {isNotEmpty(result?.prizetext) && <div><h3 className=" text-[#06152D] mb-[10px] font-bold noselect" >{result?.prizetext}</h3></div>}
                          {isNotEmpty(result?.resultText) && <div className="text-[14px] text-[#06152D] mt-[12px] noselect">{isCopied? "Copied!" :  "Coupon Code" }</div> }
                          {isNotEmpty(result?.resultText) && <div className="w-full mt-[5px] cursor-pointer mb-[10px]"  onClick={()=>copy()}><p className="flex-row flex-wrap text-[#06152D] border-dashed border-2 border-gray-300 m-auto   text-[24px] w-fit flex justify-center font-bold bg-gray-200 p-[3px] rounded-md  min-w-[150px] " > {((result?.resultText).split(""))?.map((text,inxKey)=>{ return <span  key={inxKey} className="ml-[4px] mr-[4px]">{text}</span> })} </p></div>}
                          {isNotEmpty(result?.couponDescription) && <div className="w-full mt-[15px] text-[#06152D] noselect">  <center>  {result?.couponDescription}</center></div>}
                        </div>
                      </ScratchCard>
                      :
                      <div className="flex items-center m-auto">  <CgSpinner className="w-8 h-8 animate-spin text-primary" /> <p className="text-sm font-medium m-2" > {"Loading"}  </p> </div>
                      }

                      </div>
                  
             </div>            
            }
          </> :
            <div>   
              <div className="title-text w-full max-w-[600px] flex flex-col items-center space-y-4 text-center"><p><strong  className=" text-[21px] sm:text-[26px] lg:text-[30px]" >{result?.prizetext}</strong></p></div>
                { 
                  result && isNotEmpty(result?.resultText) &&
                    <div className="w-full max-w-[600px] flex flex-col items-center space-y-4">
                        <div  >Copy your coupon code now.</div>
                        <div  className="min-w-[200px] sm:min-w-[250px] md:min-w-[350px] bg-white rounded-md  p-1 md:p-2 border-[3px]  border-dotted text-center text-[14px] sm:text-[17px] md:text-[20px]  break-all" style={{borderColor:Settings?.pegFill}}>{result?.resultText}</div>
                        <div><PrimaryButton style={{backgroundColor:Settings?.pegFill}} ring={" focus:ring-1 focus:ring-offset-1 focus:ring-[#e96d76] "} classNames={"w-[200px] sm:w-[250px] md:w-[350px] max-w-[550px]"} text={isCopied ? "Copied" :"Copy"}  type="button"   OnClick={()=>copy()} /></div>
                    </div>
                }

{ isNotEmpty(result?.resultText) && 
                        <div className="w-full max-w-[600px] flex flex-col items-center space-y-4">
                        <div className="mt-4"><PrimaryButton style={{backgroundColor:Settings?.pegFill}} ring={" focus:ring-1 focus:ring-offset-1 focus:ring-[#e96d76] "} classNames={"w-[200px] sm:w-[250px] md:w-[350px] max-w-[550px]"} text={result?.redirBtnTxt ?? "Click Now!"}  type="button"   OnClick={()=>{window.open(result?.redirURL,'_blank')}} /></div>
                        </div>
                        }
            </div> 
            }

</div>

</div>
 
);
}; 

export default SpinWheelThankyou