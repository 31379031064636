import React from "react";

const CheckBox = ({
    id= "",
    name ="",
    isChecked=false,
    label='Label name',
    labelTextsize = '',
    labelTextWeight = '',
    type="text",
    Placeholder ="",
    fontweight = "",
    margin = "mb-3",
    spaceX = 'space-x-2',
    padding = "",
    textvalue =null,
    disabledinput = false,
    width = "w-full",
    onchange = null,
    className="",
    lableColor= "#000000",
    isUrl=false,
    redirUrl="https://gozen.io",
    ...prop
}) => {
     return (
        
         <div className={`${spaceX} flex `}>
            <input type={'checkbox'} className={`w-4 h-4 text-blue-600 my-auto bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer ${className}`} id={id} name={name} placeholder={Placeholder} disabled={disabledinput} onChange = {onchange} defaultValue ={textvalue} defaultChecked={isChecked} {...prop}/>
            <label className={`flex ${labelTextsize} ${labelTextWeight}  my-auto`} style={{color:lableColor}} >
                {isUrl ? <a href={redirUrl} target="_blank" rel="noreferrer" className="hover:text-[#000071]">{label}</a>: label}
                </label>
         </div>
     )
}

export default CheckBox;